import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../Prompts/PromptsComponent";
import "./YesNoConfirm.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./YesNo_ModalStyle";
import { MODAL_STYLES_CENTERED } from "./Yesno_ModalStyle_Centered";
export default function YesNoConfirm({
	dlgOpen,
	callBackFunction,
	onClose,
	confirmMessage,
	centered = false,
}) {
	if (!dlgOpen) return null;
	return (
		<article>
			<div style={OVERLAY_STYLES}></div>
			<div
				className="yes-no-container"
				style={RESPONSIVE_MODAL_STYLES(
					centered ? MODAL_STYLES_CENTERED : MODAL_STYLES
				)}
			>
				<div className="confirm-message base-sub-title">{confirmMessage}</div>
				<button id="yes-no-cancel" className="yes-no-cancel" onClick={onClose}>
					{GetPrompts("Cancel text")}
				</button>
				<button
					id="yes-no-continue"
					className="yes-no-continue"
					onClick={() => {
						callBackFunction();
					}}
				>
					{GetPrompts("Continue text")}
				</button>
			</div>
		</article>
	);
}
